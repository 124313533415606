<!--
 * @Description: 小程序模板
 -->
<template>
  <div class="container">
    <div class="banner">
      <img src="@/assets/images/applet/banner.png" alt="" />
    </div>
    <div class="topBox">
      <div class="topBox-left">
        <img src="@/assets/images/applet/leftPhone.png" alt="" />
      </div>
      <div class="topBox-right">
        <div class="topBox-right-t1">轻松店铺装修 快速搭建在线商城小程序</div>
        <div class="topBox-right-t2">
          数十种装修组件，各式各样的搭配风格，满足不同行业的商城需求，简单的拖拽方式，三分钟即可帮助商家快速搭建属于自己个性化的商城。
        </div>
        <div class="topBox-bnt">
          <img
            class="topBox-bnt1"
            src="@/assets/images/applet/leftBnt.png"
            alt=""
          />
          <div @click="goodsDetail" class="topBox-bnt2">立即购买</div>
        </div>
      </div>
    </div>
    <div class="NakakamiBox">
      <div class="NakakamiBox-left">
        <div class="NakakamiBox-left-t1">自定义装修店铺 在线预览，快速发布</div>
        <div class="NakakamiBox-left-t2">
          完整的开店体系，提供专业的运营团队指导，通过后台装修，自定义搭建个性化独立商城，同时，提供域名/服务器购买服务，快捷发布小程序。
        </div>
        <div @click="goodsDetail" class="NakakamiBox-left-bnt">
          立即购买体验
        </div>
      </div>
      <div class="NakakamiBox-right">
        <img src="@/assets/images/applet/rightPhone.png" alt="" />
      </div>
    </div>
    <div class="LowerBox">
      <div class="LowerBox-left">
        <img src="@/assets/images/applet/leftMarketing.png" alt="" />
      </div>
      <div class="LowerBox-right">
        <div class="LowerBox-right-t1">多种营销玩法 吸粉裂变促转化</div>
        <div class="LowerBox-right-t2">
          优惠券、拼团、秒杀、积分商城、抽奖等多种营销活动，提升活跃度和留存率，激发用户购买情绪，提高转化率，助力商家的业绩增长。
        </div>
        <div @click="goodsDetail" class="LowerBox-right-bnt">立即购买体验</div>
      </div>
    </div>
    <div class="bottomBox">
      <div class="bottomBox-left">
        <div class="bottomBox-left-t1">智能数据分析 推动智慧经营决策</div>
        <div class="bottomBox-left-t2">
          通过对商品、交易、会员等多维度店铺经营分析，以数据看板做为展示形式，高效且直观的帮助商家调整营销策略，促进业绩增长。
        </div>
        <div @click="goodsDetail" class="bottomBox-left-bnt">立即购买体验</div>
      </div>
      <div class="bottomBox-right">
        <img src="@/assets/images/applet/rightData.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios
        .get("/api/front/products?cid=" + 743, {})
        .then((res) => {
          // 获取种类的响应
          console.log("@@获取商品所有种类的响应：", res);
          this.list = res.data.data.list;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    goodsDetail() {
      if (this.list && this.list.length) {
        this.$router.push({
          path: "/web/goodsDetail",
          query: { goodsId: this.list[0].id, flag: 2 },
        });
      } else {
        alert("暂无数据");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  font-family: "JDZY";
  padding: 0;
  margin-bottom: 50px;
  .banner {
    // position: relative;
    // width: 100%;
    // height: 360px;
    // overflow: hidden;
    img {
      width: 100%;
      // height: 100%;
      // object-fit: cover;
    }
  }
  .topBox {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .topBox-left {
      img {
        width: 240px;
        height: 500px;
      }
    }
    .topBox-right {
      margin-top: 80px;
      margin-left: 50px;
      .topBox-right-t1 {
        font-size: 30px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.85);
        width: 536px;
        height: 42px;
      }
      .topBox-right-t2 {
        width: 800px;
        height: 80px;
        margin-top: 20px;
        font-size: 20px;
        font-weight: normal;
        line-height: 40px;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.65);
      }
      .topBox-bnt {
        display: flex;
        align-items: center;
        margin-top: 50px;
        .topBox-bnt1 {
          width: 104px;
          height: 28px;
          opacity: 1;
          margin-right: 30px;
          cursor: pointer;
        }
        .topBox-bnt2 {
          font-size: 18px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0em;
          color: #ffffff;
          left: 0px;
          top: 0px;
          width: 120px;
          height: 42px;
          border-radius: 4px;
          opacity: 1;
          text-align: center;
          line-height: 42px;
          background: #ea4819;
          cursor: pointer;
        }
      }
    }
  }
  .NakakamiBox {
    margin-top: 50px;
    width: 100%;
    height: 476px;
    opacity: 1;
    display: flex;
    justify-content: center;
    background: #f8f9ff;
    .NakakamiBox-left {
      margin-right: 50px;
      width: 700px;
      margin-top: 130px;
      .NakakamiBox-left-t1 {
        font-size: 30px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.85);
      }
      .NakakamiBox-left-t2 {
        margin-top: 20px;
        font-size: 20px;
        font-weight: normal;
        line-height: 40px;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.65);
      }
      .NakakamiBox-left-bnt {
        margin-top: 50px;
        width: 150px;
        height: 42px;
        border-radius: 4px;
        opacity: 1;
        background: #ea4819;
        text-align: center;
        font-size: 18px;
        line-height: 42px;
        letter-spacing: 0em;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .NakakamiBox-right {
      margin-top: 50px;
      img {
        width: 350px;
        height: 376px;
      }
    }
  }
  .LowerBox {
    display: flex;
    justify-content: center;
    .LowerBox-left {
      margin-right: 50px;
      margin-top: 50px;
      opacity: 1;
      img {
        width: 400px;
        height: 360px;
      }
    }
    .LowerBox-right {
      margin-top: 110px;
      width: 670px;
      .LowerBox-right-t1 {
        font-size: 30px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.85);
      }
      .LowerBox-right-t2 {
        margin-top: 20px;
        font-size: 20px;
        font-weight: normal;
        line-height: 40px;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.65);
      }
      .LowerBox-right-bnt {
        margin-top: 50px;
        width: 150px;
        height: 42px;
        border-radius: 4px;
        opacity: 1;
        background: #ea4819;
        text-align: center;
        font-size: 18px;
        line-height: 42px;
        letter-spacing: 0em;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .bottomBox {
    margin-top: 50px;
    width: 100%;
    height: 510px;
    opacity: 1;
    display: flex;
    justify-content: center;
    background: #f8f9ff;
    .bottomBox-left {
      margin-right: 60px;
      width: 600px;
      margin-top: 102px;
      .bottomBox-left-t1 {
        font-size: 30px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.85);
      }
      .bottomBox-left-t2 {
        margin-top: 20px;
        font-size: 20px;
        font-weight: normal;
        line-height: 40px;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.65);
      }
      .bottomBox-left-bnt {
        margin-top: 50px;
        width: 150px;
        height: 42px;
        border-radius: 4px;
        opacity: 1;
        background: #ea4819;
        text-align: center;
        font-size: 18px;
        line-height: 42px;
        letter-spacing: 0em;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .bottomBox-right {
      margin-top: 50px;
      img {
        width: 460px;
        height: 410px;
      }
    }
  }
}
</style>
